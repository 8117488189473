
<template>
  <div>
    <div v-if="!isHtml" class="block text-sm font-semibold text-gray-700">{{
      label
    }}</div>
    <div v-else class="block text-gray-700" :class="isLarge ? 'text-md font-normal': 'text-sm font-semibold'" v-html="label" />
    <div class="mt-1">
      <textarea v-model="inputValue" rows="4" :class="customClass" :placeholder="placeholder" @focus="$emit('focus')"
        @blur="$emit('blur')" class="
                shadow-sm
                focus:ring-primary-500 focus:border-primary-500
                ring-1
                ring-gray-300
                block
                w-full
                sm:text-sm
                border-gray-300
                rounded-md
                p-2
              " />
    </div>
  </div>
</template>
<script>
/**
 * - Use it to show simple textarea
 */
export default {
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
    /**
     * Use to show what textarea is for
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Placeholder of textarea
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Use it to apply extra class to textarea
     */
    customClass: {
      type: String,
      default: "",
    },
    /**
     * Use to catch dynamic value of textarea
     */
    value: {
      type: String,
      default: "",
    },
    isHtml: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>
