<template>
  <a class="border bg-white" @click="$emit('onClick')" :class="customClass">
    <div class="p-4 sm:p-8">
      <div class="inline-flex flex-col sm:flex-row justify-between w-full items-start sm:items-center">
        <h2 class="text-2xl font-medium tracking-tight text-gray-900 mb-3 sm:mb-0">
          {{ title }}&nbsp;
        </h2>
        <Badge :is-rounded="true" text-size="sm" px="3" py="2" bg-color="gray-400" text-color="white"
          :label="convertEnumToRead(actionLabel)" />
      </div>
      <div class="hidden sm:inline-flex flex-row w-full mt-6">
        <div class="flex items-center px-2" v-for="item in subtitles">
          <Icons :name="item.icon" size="4" color="gray-500" hover-text-color="gray-700" />
          <p v-if="item.name" class="text-ellipsis font-medium overflow-hidden whitespace-nowrap mx-2">
            {{ item.name }}</p>
          <Badge v-if="item.count" :is-rounded="true" text-size="sm" px="2" py="0" bg-color="gray-400" text-color="black"
            :label="item.count" />
        </div>
      </div>
    </div>
    <div v-if="survey" class="hidden sm:block border-t px-8 py-4 overflow-x-scroll bg-gray-100">
      <table class="w-full text-left survey-table">
        <thead>
          <tr>
            <th v-for="item in Object.keys(survey)" class="whitespace-nowrap font-medium">{{ item }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border bg-white">
            <td v-for="item in Object.keys(survey)">
              {{ item !== "Magnitude" ? survey[item] : "" }}
              <div class="flex items-center" v-if="item === 'Magnitude'">
                <Badge :is-rounded="true" text-size="sm" px="2" py="0"
                  :bg-color="getBgColor(get(survey[item], 'magnitude'))" text-color="black"
                  :label="get(survey[item], 'magnitude')" />
                <div class="progress-bar ml-3">
                  <span class="bg-gray-400" :style="{ width: get(survey[item], 'out') + '%' }"></span>
                  <span class="bg-orange-400" :style="{ width: get(survey[item], 'low') + '%' }"></span>
                  <span class="bg-amber-300" :style="{ width: get(survey[item], 'mid') + '%' }"></span>
                  <span class="bg-green-500" :style="{ width: get(survey[item], 'hi') + '%' }"></span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="survey" class="sm:hidden px-4 sm:px-8 pb-8 overflow-x-scroll">
      <table class="border w-full mobile-bordered-table overflow-x-scroll">
        <tbody>
          <template v-for="item in subtitles">
            <tr v-if="item.count">
              <td class="px-4 py-2">
                <p class="inline-flex items-center text-xs">
                  <Icons :name="item.icon" size="4" color="gray-500" hover-text-color="gray-700" />
                  <span class="ml-2">{{ item.name }}</span>
                </p>
              </td>
              <td class="px-4 py-2 text-xs">{{ item.count }}</td>
            </tr>
          </template>
          <tr v-if="Object.keys(survey).length" v-for="item in Object.keys(survey)">
            <td class="px-4 py-2 whitespace-nowrap text-xs">{{ item }}</td>
            <td class="px-4 py-2 text-xs">
              {{ item === "Magnitude" ? "" : survey[item] }}
              <Badge v-if="item === 'Magnitude'" :is-rounded="true" text-size="xs" px="2" py="0"
                :bg-color="magnitude_color" text-color="black" :label="survey[item]" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </a>
</template>

<script>
import Badge from "../../Badge/Basic/Basic.vue";
import Dropdown from "../../Dropdown/Dropdown/Dropdown.vue";
import Link from "../../../atoms/Link/Link.vue";
import Icons from "../../../atoms/Icons/Icons.vue";
import { convertEnumToRead } from '../../../../utils/utils.js'
import { get } from 'lodash'

export default {
  name: "DetailViewHeader",
  components: {
    Icons,
    Link,
    Badge,
    Dropdown
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    actionLabel: {
      type: String,
      default: "",
    },
    subtitles: {
      type: Array,
      default: () => [],
    },
    survey: {
      type: Object,
      default: () => {
      },
    },
    customClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      magnitude_color: "gray-400",
      convertEnumToRead,
      get,
    }
  },
  methods: {
    getBgColor(value) {
      console.log(value, 'value')
      if (value < 1) {
        return 'gray-400'
      } else if (value >= 1 && value < 25) {
        return 'orange-400'
      } else if (value >= 25 && value < 50) {
        return 'amber-300'
      } else if (value >= 50) {
        return 'green-400'
      }
    }
  }
};
</script>


<style>
.text-ellipsis {
  text-overflow: ellipsis;
}

.survey-table td,
.survey-table th {
  padding: 10px;
}

.progress-bar {
  width: 100px;
  height: 20px;
  display: inline-flex;
}

.flex-7 {
  flex: 7;
}

.mobile-bordered-table>tbody>tr>td {
  border-bottom: 1px solid #e4e4e7;
}

.mobile-bordered-table>tbody>tr:last-child>td {
  border-bottom: 0;
}

.mobile-bordered-table>tbody>tr>td {
  border-left: 1px solid #e4e4e7;
}

.mobile-bordered-table>tbody>tr>td:first-child {
  border-left: 0;
}
</style>
