<template>
	<div class="px-0 sm:px-8 py-0 sm:py-4">
		<div class="inline-flex flex-row justify-between w-full space-x-8">
			<div class="flex flex-col lg:flex-row">
				<h1 class="sm:text-3xl text-xl font-bold flex items-center font-medium tracking-tight text-gray-700 mr-2">
					{{ title }}
				</h1>
				<Badge v-if="isActive" :is-rounded="true" text-size="sm" px="3" py="2" bg-color="gray-400" text-color="white"
					text-weight="medium" :label="badgeText" class="mt-2 lg:mt-1 self-start" />
			</div>
			<div class="flex items-center space-x-4">
				<div class="sm:block hidden">
					<Dropdown label="Options" :options="options" :menuItemClicked="(item) => $emit('onClickItem', item)"></Dropdown>
				</div>
				<div class="sm:hidden block">
					<Dropdown :options="options" :menuItemClicked="(item) => $emit('onClickItem', item)"></Dropdown>
				</div>
				<a v-if="isRemove">
					<Icons name="TrashIcon" color="red-600" size="10" custom-class="cursor-pointer" @click="$emit('onRemove')" />
				</a>
			</div>

		</div>
		<div class="inline-flex flex-col sm:flex-row mt-6">
			<a @click="onBack" v-if="backLabel" class="mr-4 mb-2 cursor-pointer">
				<span class="inline-flex items-center underline text-sm text-primary-900">
					<Icons name="ArrowLeftIcon" size="4" color="primary-900" customClass="mr-2" hover-text-color="primary-900" />
					{{ backLabel }}
				</span>
			</a>
			<Link :url="actionUrl" :leading="true" v-if="actionLabel">
			<span class="inline-flex items-center underline text-sm text-primary-900">
				<Icons name="MapIcon" size="4" color="primary-900" customClass="mr-2" hover-text-color="primary-900" />
				{{ actionLabel }}
			</span>
			</Link>
		</div>
	</div>
</template>

<script>
import Badge from "../Badge/Basic/Basic.vue";
import Dropdown from "../Dropdown/Dropdown/Dropdown.vue";
import Link from "../../atoms/Link/Link.vue";
import Icons from "../../atoms/Icons/Icons.vue";

export default {
	name: "DetailViewHeader",
	components: {
		Icons,
		Link,
		Badge,
		Dropdown
	},
	props: {
		title: {
			type: String,
			default: "",
		},
		options: {
			type: Array,
			default: null,
		},
		backUrl: {
			type: String,
			default: "",
		},
		backLabel: {
			type: String,
			default: "",
		},
		actionUrl: {
			type: String,
			default: "",
		},
		actionLabel: {
			type: String,
			default: "",
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		customClass: {
			type: String,
			default: ''
		},
		badgeText: {
			type: String,
			default: 'Active'
		},
		onBack: {
			type: Function,
			default: () => { }
		},
		isRemove: {
			type: Boolean,
			default: false,
		}
	},
};
</script>
