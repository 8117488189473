<template>
  <SideOver v-if="isLoad" :title="title" :is-show="isShow" :label="content" :submitBtnText="submitBtnText"
    @onClose="onClose" @onSubmit="() => onSubmit()" :isLoading="isLoading">
    <div class="flex-1 h-full p-6 space-y-4 overflow-y-auto">
      <div v-for="item in formGroup">
        <Select v-if="item.type === 'select'" :label="item.label" :items="list" customClass="w-full"
          v-model:selectedItem="item.selectedItem" />
        <Input v-else-if="item.type === 'text' || item.type === 'email'" :label="item.label"
          :placeholder="item.placeholder" :type="item.type" v-model:value="item.value" />
        <AutoComplete v-else :label="item.label" :listData="item.options" :placeholder="`${item.placeholder
          ? item.placeholder
          : 'Start typing to filter'
          }`" v-model:selectedValue="formData[item.label]" />
      </div>
    </div>
  </SideOver>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Select from '../../../molecules/Selects/Component/Component.vue'
import Button from '../../../atoms/Button/Button.vue'
import SideOver from '../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue'
import AutoComplete from '../../../molecules/AutoComplete/AutoComplete.vue'

export default {
  components: {
    Text,
    Icon,
    Input,
    Select,
    Button,
    SideOver,
    AutoComplete
  },

  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    formGroup: {
      type: Array,
      default: () => []
    },
    buttons: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    submitBtnText: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    onSubmit() {
      console.log('onSubmit')
      this.$emit('onSubmit', this.formGroup)
    },
    onClose() {
      this.$emit('onClose');
      setTimeout(() => {
        this.isLoad = false;
      }, 700)
    }
  },
  watch: {
    isShow: function (val) {
      console.log(val, 'val')
      if (val) {
        setTimeout(() => (this.isLoad = true), 100);
      } else {
        setTimeout(() => (this.isLoad = false), 700);
      }
    },
  },
  data() {
    return {
      isLoad: false,
    }
  }
}
</script>
