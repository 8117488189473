import numeral from "numeral";
import storage from "../store/effects/storage";
import { Settings } from "../../settings";
import { upperFirst } from "lodash";

/*
 *
 */
export const formatCurrencyUnd = function (price, format = "00") {
  const decimals = format.length;
  const dec = Math.floor(price);
  const und =
    (Number(Math.round(price + "e" + decimals) + "e-" + decimals) - dec) * 100;
  return numeral(und).format(format);
};

/*
 *
 */
export const floor = function (value, place) {
  const pow = Math.pow(10, place || 0); // eslint-disable-line
  const val = Math.floor(value * pow) / pow;
  return val.toFixed(place);
};

/*
 *
 */
export const formatCurrencyDec = function (price, format = "$0,0") {
  const nprice = numeral(floor(price, 0));
  return nprice.format(format);
};

/*
 *
 */
export const getAWSCredential = async (actions) => {
  const credential = await storage.getData("aws_credential");
  if (new Date(credential?.s3?.Credentials.Expiration) > new Date()) {
    return { s3: credential?.s3, mediaConvert: credential?.mediaConvert };
  } else {
    const { awsTempToken: s3Token } = await actions.user.awsTempToken({
      service: "s3",
    });
    const { awsTempToken } = await actions.user.awsTempToken({
      service: "mediaConvert",
    });
    await storage.saveData(
      { s3: s3Token, mediaConvert: awsTempToken },
      "aws_credential"
    );
    return { s3: s3Token, mediaConvert: awsTempToken };
  }
};

/*
 *
 */
export const getUploadedFileLink = async (file) => {
  const data = file;
  const splits = data.name.split(".");
  splits.pop();
  const dirPath = "base-admin/";
  data.filename = splits?.join(".");
  data.filename = data.filename.split(" ").join("-");
  const paths = data.filename.split(".");
  if (paths.length > 0) paths[paths.length - 1] += `.${new Date().getTime()}`;
  data.filename =
    new Date().getTime() +
    "." +
    data.name.split(".")[data.name.split(".").length - 1];
  // data.filename = dirPath + data.filename

  try {
    const s3Bucket = new AWS.S3({
      params: { Bucket: Settings.aws.bucket },
      apiVersion: "2006-03-01",
      region: Settings.aws.region,
      credentials: {
        accessKeyId: Settings.aws.accessKeyId,
        secretAccessKey: Settings.aws.secretAccessKey,
      },
    });

    console.log(s3Bucket, "s3bucket");
    const params = {
      AccelerateConfiguration: {
        Status: "Enabled",
      },
      Bucket: Settings.aws.bucket,
    };
    await s3Bucket.putBucketAccelerateConfiguration(params).promise();

    s3Bucket.config.useAccelerateEndpoint = true;

    const multiPartParams = {
      Bucket: Settings.aws.bucket,
      Key: dirPath + data.filename,
    };
    const multipart = await s3Bucket
      .createMultipartUpload(multiPartParams)
      .promise();
    const multipartMap = { Parts: [] };
    let partNum = 0;
    let size = 5 * 1024 * 1024,
      chunks = Math.ceil(data.size / size);
    for (let i = 0; i < chunks; i++) {
      partNum += 1;
      const partParams = {
        ...multiPartParams,
        Body: data.slice(
          i * size,
          Math.min(i * size + size, data.size),
          data.type
        ),
        PartNumber: String(partNum),
        UploadId: multipart.UploadId,
      };
      const result = await s3Bucket.uploadPart(partParams).promise();
      multipartMap.Parts[partNum - 1] = {
        ETag: result.ETag,
        PartNumber: Number(partNum),
      };
    }
    const doneParams = {
      ...multiPartParams,
      MultipartUpload: multipartMap,
      UploadId: multipart.UploadId,
    };
    await s3Bucket.completeMultipartUpload(doneParams).promise();

    if (data.type?.includes("video")) {
      AWS.config.update({
        region: "us-west-2",
        accessKeyId: Settings.aws.accessKeyId,
        secretAccessKey: Settings.aws.secretAccessKey,
      });

      if (!AWS.config.mediaconvert) {
        const mediaConvert = new MediaConvert({ apiVersion: "2017-08-29" });
        const params = { MaxResults: 0 };
        const { Endpoints } = await mediaConvert
          .describeEndpoints(params)
          .promise();
        AWS.config.mediaconvert = { endpoint: Endpoints[0]?.Url };
      }

      const param = {
        Queue: "arn:aws:mediaconvert:us-west-2:626555668917:queues/Default",
        UserMetadata: {},
        Role: "arn:aws:iam::626555668917:role/Media_Convert_Role",
        Settings: {
          TimecodeConfig: {
            Source: "ZEROBASED",
          },
          OutputGroups: [
            {
              CustomName: "MP4 - " + Settings.appName,
              Name: "File Group",
              Outputs: [
                {
                  ContainerSettings: {
                    Container: "MP4",
                    Mp4Settings: {},
                  },
                  VideoDescription: {
                    CodecSettings: {
                      Codec: "H_264",
                      H264Settings: {
                        MaxBitrate: 5000000,
                        RateControlMode: "QVBR",
                        SceneChangeDetect: "TRANSITION_DETECTION",
                      },
                    },
                  },
                  AudioDescriptions: [
                    {
                      CodecSettings: {
                        Codec: "AAC",
                        AacSettings: {
                          Bitrate: 96000,
                          CodingMode: "CODING_MODE_2_0",
                          SampleRate: 48000,
                        },
                      },
                    },
                  ],
                },
              ],
              OutputGroupSettings: {
                Type: "FILE_GROUP_SETTINGS",
                FileGroupSettings: {
                  Destination: `s3://${Settings.aws.bucket}/${dirPath}/converted/`,
                  DestinationSettings: {
                    S3Settings: {
                      AccessControl: {
                        CannedAcl: "PUBLIC_READ",
                      },
                    },
                  },
                },
              },
            },
            {
              Name: "File Group",
              OutputGroupSettings: {
                Type: "FILE_GROUP_SETTINGS",
                FileGroupSettings: {
                  Destination: `s3://${Settings.aws.bucket}/${dirPath}/converted/`,
                  DestinationSettings: {
                    S3Settings: {
                      AccessControl: {
                        CannedAcl: "PUBLIC_READ",
                      },
                    },
                  },
                },
              },
              Outputs: [
                {
                  VideoDescription: {
                    CodecSettings: {
                      Codec: "FRAME_CAPTURE",
                      FrameCaptureSettings: {
                        FramerateNumerator: 30,
                        FramerateDenominator: 900,
                      },
                    },
                    ScalingBehavior: "DEFAULT",
                    Sharpness: 50,
                  },
                  ContainerSettings: {
                    Container: "RAW",
                  },
                  Extension: "jpg",
                },
              ],
              CustomName: "Thumbnail - " + Settings.appName,
            },
          ],
          Inputs: [
            {
              AudioSelectors: {
                "Audio Selector 1": {
                  DefaultSelection: "DEFAULT",
                },
              },
              VideoSelector: {
                Rotate: "AUTO",
              },
              TimecodeSource: "ZEROBASED",
              FileInput:
                "s3://" + Settings.aws.bucket + "/" + dirPath + data.filename,
            },
          ],
        },
        AccelerationSettings: {
          Mode: "DISABLED",
        },
        StatusUpdateInterval: "SECONDS_10",
        Priority: 0,
      };

      const res = await new AWS.MediaConvert({ apiVersion: "2017-08-29" })
        .createJob(param)
        .promise();
      const prefix = `https://${Settings.aws.bucket}.s3.us-west-2.amazonaws.com/`;
      const videoPath = `${prefix}${
        dirPath + "converted/" + data.filename.split(".")[0]
      }.mp4`;
      const thumbnailPath = `${prefix}${
        dirPath + "converted/" + data.filename.split(".")[0]
      }.0000000.jpg`;
      console.log(videoPath, thumbnailPath);
      return { avatar: thumbnailPath, source: videoPath };
    } else {
      const obj = {
        ResponseContentDisposition: `attachment; filename=${
          dirPath + data.filename
        }`,
      };
      const res = await s3Bucket.getSignedUrlPromise("getObject", {
        Key: dirPath + data.filename,
        Expires: 60 * 60 * 24 * 365 * 2,
        ...obj,
      });
      return { source: res };
    }
    //   }
    // )
  } catch (e) {
    console.log(e);
  }
};

export const convertEnumToRead = (val) => {
  const items = val?.split("_");
  const updatedItems = [];
  (items || [])?.map((item) =>
    updatedItems.push(upperFirst(item?.toLowerCase()))
  );
  return updatedItems.join(" ");
};

export const stripHtml = (html) => {
  let body = html;
  body = body.replaceAll("<br>", "\n");
  let tmp = document.createElement("div");
  tmp.innerHTML = body;
  console.log(tmp.textContent || tmp.innerText || "");
  return tmp.textContent || tmp.innerText || "";
};

export const convertActionWord = (text) => {
  return text?.replaceAll('theirs', 'yours')?.replaceAll('their', 'your')?.replaceAll('themselves', 'yourself')?.replaceAll('them', 'you')?.replaceAll('they', 'you')?.replaceAll('they\'ll', 'you\'ll')?.replaceAll("they're", "you're")
}