<template>
    <div class="">
        <DetailViewHeader
            :title="cohort?.name"
            :options="detailViewHeader.options"
            :on-back="() => $router.go(-1)"
            :backLabel="cohort?.program?.name"
            :actionUrl="''"
            :actionLabel="''"
            :badge-text="`${moment(cohort?.startDate).format('M/D')} - ${moment(
                cohort?.endDate
            ).format('M/D')}`"
            customClass="mx-2 sm:mx-6"
            class="mt-4"
            :isActive="cohort?.startDate && cohort?.endDate"
        />
        <SubNavigationBar
            @onSelect="
                (item) => {
                    selectedTab = item;
                    keyword = null;
                }
            "
            :hide-icon="false"
            :tabs="subNavigationBar"
            customClass="mt-4 mx-0 sm:mx-8"
        />
        <div class="mt-4 mx-0 sm:mx-8" v-if="selectedTab?.name === 'Details'">
            <div class="shadow-lg h-fit rounded-md">
                <div
                    class="px-8 py-8 grid grid-cols-2 gap-4 bg-white border-t border-l border-r border-gray-300 rounded-t-md"
                >
                    <div
                        v-for="field in formGroup[0].fields"
                        :key="field.id"
                        :class="`col-span-${field.cols}`"
                    >
                        <Input
                            v-if="
                                field.inputType === 'TEXT' ||
                                field.inputType === 'DATE'
                            "
                            :label="field.label"
                            :type="field.inputType.toLowerCase()"
                            :placeholder="field.placeholder"
                            v-model:value="formData[field.modelField]"
                        />
                        <Select
                            v-else-if="field.inputType === 'DROPDOWN'"
                            :label="field.label"
                            :placeholder="field.placeholder"
                            v-model:selected-item="formData[field.modelField]"
                            :items="JSON.parse(field.options)"
                        />
                    </div>
                </div>
                <div
                    class="flex flex-row w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md"
                >
                    <div
                        class="flex flex-row items-end justify-end w-full my-4 mr-4 sm:mr-3 lg:mr-5"
                    >
                        <Button
                            content="Cancel"
                            :variant="null"
                            customClass="bg-white text-black underline px-2 bg-gray-100"
                        />
                        <Button
                            content="Save"
                            variant="primary"
                            customClass="ml-4 px-4"
                            :isLoading="isLoading"
                            :click="saveCohortForm"
                        />
                    </div>
                </div>
            </div>
            <div class="border rounded-md shadow-lg px-6 py-4 bg-gray-100 mt-5">
                <Text
                    size="md"
                    weight="semibold"
                    color="black"
                    content="Invite people to this cohort"
                />
                <Text
                    custom-class="mt-4"
                    size="xs"
                    color="black"
                    content="You can email this link to people that you'd like to join this cohort and they will either be able to add their first idea when they create an account or pick which idea they'd like to use if they already have and account."
                />
                <div class="mt-4">
                    <Text
                        size="xs"
                        weight="semibold"
                        color="black"
                        content="Registration Link"
                    />
                    <div
                        class="border rounded-md border-gray-300 relative px-3 py-2 flex justify-between items-center mt-1"
                    >
                        <input
                            v-model="copyUrl"
                            autocomplete="off"
                            class="border-none outline-none focus:ring-0 focus:border-none focus:outline-none w-full bg-gray-100"
                        />
                        <Icon
                            name="DocumentDuplicateIcon"
                            size="6"
                            color="gray-500"
                            custom-class="cursor-pointer"
                            @click="() => onCopyUrl(copyUrl)"
                        />
                    </div>
                </div>
            </div>
            <!-- <div
                class="bg-gray-100 border rounded-md px-6 py-4 mt-4 shadow-lg mb-10"
            >
                <div class="">
                    <Text
                        size="md"
                        weight="semibold"
                        color="black"
                        content="Cohort Admins"
                    />
                    <Text
                        custom-class="mt-4"
                        size="xs"
                        color="black"
                        content="These are the program admins and any admins that will be unique to this cohort like a facilitator"
                    />
                    <div
                        class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300 mt-4"
                    >
                        <div
                            v-for="contact in contacts"
                            :key="contact.type"
                            class="grid grid-cols-4 py-3 px-2"
                        >
                            <div class="col-span-2">
                                <Text
                                    size="sm"
                                    weight="semibold"
                                    color="black"
                                    :content="contact.value"
                                />
                            </div>
                            <div class="col-span-1">
                                <Badge
                                    :label="contact.type"
                                    isLeft
                                    bg-color="gray-500"
                                    is-rounded
                                    px="3"
                                    py="1"
                                    text-color="white"
                                />
                            </div>
                            <div class="col-span-1 flex justify-end">
                                <a
                                    class="px-1"
                                    @click="
                                        (isShowUser = true), (user = contact)
                                    "
                                >
                                    <Icon
                                        name="PencilAltIcon"
                                        color="primary-600"
                                        class="hover:text-primary-900"
                                    />
                                </a>
                                <a
                                    @click="
                                        (isOpenUserRemove = true),
                                            (selectedUser = contact)
                                    "
                                    class="px-1"
                                >
                                    <Icon
                                        name="TrashIcon"
                                        color="red-600"
                                        class="hover:text-red-900"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <span
                        class="cursor-pointer"
                        :onClick="() => ((isShowUser = true), (user = null))"
                    >
                        <Text
                            size="sm"
                            color="primary-600"
                            content="+ Add"
                            decoration="underline"
                            custom-class="cursor-pointer mt-5"
                        />
                    </span>
                </div>
            </div> -->
        </div>
        <div class="mt-4 mx-0 sm:mx-8" v-if="selectedTab.name === 'Ventures'">
            <ListViewHeader :title="`Ventures (${teams?.length})`" />
            <div class="space-y-3 mt-5">
                <div v-if="teams && teams.length">
                    <CardWithBadge
                        v-for="team in getItems(teams, 'ventures')"
                        :key="team.id"
                        v-bind="team"
                        @onClick="onClickVentureTeam(team.id)"
                    />
                    <CardFooterWithPageButtons
                        class="px-0 bg-opacity-0"
                        :onChangePerPage="
                            (count) => perPageChanged(count, 'ventures')
                        "
                        :totalRecords="teams?.length"
                        :perPage="pagination.ventures.recordPerPage"
                        :currentPage="pagination.ventures.recordActivePage"
                        :onChangePage="(page) => pageChanged(page, 'ventures')"
                    />
                </div>
                <EmptyState
                    v-else
                    class=""
                    isFull
                    :description="'No records available!'"
                />
            </div>
        </div>
        <div class="mt-4 mx-0 sm:mx-8" v-if="selectedTab.name === 'People'">
            <ListViewHeader
                :title="`People (${people?.length})`"
                btn-text="Add"
                @onClick="() => (isShowPeople = true)"
                isShowSearchBox
                @onSearch="(key) => (keyword = key)"
            />
            <div class="flex justify-end items-center mt-3">
                <Text
                    @click="onExport"
                    size=""
                    color="indigo-600"
                    content="Export as CSV"
                    customClass="underline cursor-poitnter"
                />
            </div>
            <div class="space-y-3 mt-3">
                <div v-if="people && people.length">
                    <Table
                        :list="getItems(people, 'people')"
                        isBoldTeam
                        isClickable
                        @onClickItem="
                            (item) =>
                                $router.push({
                                    name: 'Ecosystem People Detail',
                                    params: {
                                        companyId: company?.id,
                                        id: item?.id,
                                    },
                                })
                        "
                        :sortFields="['Name', 'Email', 'Team']"
                        @onSort="(item) => (sortPeople = item)"
                    />
                    <CardFooterWithPageButtons
                        class="px-0 bg-opacity-0"
                        :onChangePerPage="
                            (count) => perPageChanged(count, 'people')
                        "
                        :totalRecords="getList(people)?.length"
                        :perPage="pagination.people.recordPerPage"
                        :currentPage="pagination.people.recordActivePage"
                        :onChangePage="(page) => pageChanged(page, 'people')"
                    />
                </div>
                <EmptyState
                    v-else
                    class=""
                    isFull
                    :description="'No records available!'"
                />
            </div>
        </div>
        <div
            class="mt-4 mx-0 sm:mx-8"
            v-if="selectedTab.name === 'Opportunities'"
        >
            <div class="flex items-center space-x-5">
                <Text
                    color="black"
                    weight="bold"
                    size="2xl"
                    customClass="sm:text-2xl"
                    :content="`${opportunity.title} (${opportunity?.items?.length})`"
                />
            </div>
            <div class="space-y-3 mt-5">
                <div v-if="opportunity?.items?.length > 0">
                    <Table
                        :list="
                            getItems(
                                opportunity?.items?.sort((a, b) =>
                                    a?.Magnitude?.magnitude >
                                    b?.Magnitude?.magnitude
                                        ? -1
                                        : 1
                                ),
                                'opportunities'
                            )
                        "
                        :sortFields="[
                            'Opportunity',
                            'Venture',
                            'Responses',
                            'Magnitude',
                            'Market',
                        ]"
                        @onSort="(item) => (sortOpportunity = item)"
                    />
                    <CardFooterWithPageButtons
                        class="px-0 bg-opacity-0"
                        :onChangePerPage="
                            (count) => perPageChanged(count, 'opportunities')
                        "
                        :totalRecords="people?.length"
                        :perPage="pagination.opportunities.recordPerPage"
                        :currentPage="pagination.opportunities.recordActivePage"
                        :onChangePage="
                            (page) => pageChanged(page, 'opportunities')
                        "
                    />
                </div>

                <EmptyState
                    v-if="opportunity?.items?.length === 0"
                    class=""
                    isFull
                    :description="'No records available!'"
                />
            </div>
        </div>
        <InvitePeople
            v-bind="{
                ...c.invitePeople,
                isShow: isShowPeople,
                isLoading,
                url: inviteLink,
                title: 'Let\'s invite some people to participate in this cohort.',
                content: 'You\'ll be able to track  their progress',
            }"
            @onClose="() => (isShowPeople = false)"
            @onSubmit="(item) => onSendInvite(item)"
        />
        <InviteMember
            :is-show="isShowUser"
            v-bind="{
                ...c.inviteMember,
                formGroup: inviteMemberData(),
                isShow: isShowUser,
                list: state.group.groups,
                formData: inviteForm,
            }"
            :isLoading="isUserSaving"
            @onClose="() => ((isShowUser = false), (user = null))"
            @onSubmit="(item) => handleUser(item)"
        />
        <DeleteModal
            :is-show="isOpenUserRemove"
            @onClose="
                () => {
                    (isOpenUserRemove = false), (selectedUser = null);
                }
            "
            :title="`Remove this <b>User</b>?`"
            :description="`Are you sure you want to remove the user <b>${
                (selectedUser?.firstName || '') +
                ' ' +
                (selectedUser?.lastName || '')
            }</b> from cohort <b>${cohort?.name || ''}</b>?`"
            yes-btn-text="Remove"
            no-btn-text="Cancel"
            icon="TrashIcon"
            yesBtnVariant="danger"
            noBtnVariant="secondary"
            :on-no-click="
                () => {
                    (isOpenUserRemove = false), (selectedUser = null);
                }
            "
            :on-yes-click="() => removeUserFromCohort(selectedUser)"
        />
    </div>
</template>

<script>
import ListViewItem from "@/components/organisms/ListViewItem/ListViewItem";
import DetailViewHeader from "@/components/molecules/DetailViewHeader/DetailViewHeader";
import SubNavigationBar from "@/components/molecules/SubnavigationBar/SubnavigationBar.vue";
import { c } from "@/components/constants";
import { cohortDetailForm } from "../../.storybook/sampleData";
import Button from "@/components/atoms/Button/Button";
import Input from "@/components/molecules/Inputs/Component/Component";
import Select from "@/components/molecules/Selects/Component/Component";
import Text from "@/components/atoms/Text/Text";
import Badge from "@/components/molecules/Badge/Basic/Basic";
import Icon from "@/components/atoms/Icons/Icons";
import Table from "@/components/organisms/Tables/Simple/Simple";
import EcosystemVentureTeams from "@/pages/EcosystemVentureTeams";
import EcosystemPeople from "@/components/templates/EcosystemPeople/EcosystemPeople";
import ListViewHeader from "../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton";
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import { Settings } from "../../settings";
import moment from "moment";
import InvitePeople from "@/components/organisms/Modals/InvitePeople/InvitePeople";
import InviteMember from "../components/organisms/Modals/InviteMember/InviteMember.vue";
import DeleteModal from "../components//organisms/Modals/SimpleAlert/SimpleAlert";
import EmptyState from "@/components/organisms/EmptyState/Simple/Simple";
import Items from "@/components/molecules/CardListItem/CardWithSurveyResult/CardWithSurveyResult";
import useClipboard from "vue-clipboard3";
import CardFooterWithPageButtons from "@/components/organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import numeral from "numeral";
import { json } from "overmind";
import { get, upperFirst, groupBy, mapValues, size, max } from "lodash";

export default {
    components: {
        EcosystemPeople,
        EcosystemVentureTeams,
        ListViewItem,
        DetailViewHeader,
        SubNavigationBar,
        Button,
        Input,
        Select,
        Text,
        Badge,
        Icon,
        Table,
        ListViewHeader,
        CardWithImage,
        CardWithBadge,
        moment,
        InvitePeople,
        InviteMember,
        DeleteModal,
        EmptyState,
        Items,
        CardFooterWithPageButtons,
    },
    computed: {
        subNavigationBar() {
            return [
                {
                    name: "Details",
                    count: null,
                    href: "#",
                    icon: "PencilIcon",
                    current: false,
                },
                {
                    name: "Ventures",
                    count: this.teams?.length,
                    href: "#",
                    icon: "LightBulbIcon",
                    current: false,
                },
                {
                    name: "People",
                    count: this.people?.length,
                    href: "#",
                    icon: "UsersIcon",
                    current: false,
                },
                {
                    name: "Opportunities",
                    count: this.opportunity.items?.length,
                    href: "#",
                    icon: "ExclamationIcon",
                    current: false,
                },
            ];
        },
    },
    data() {
        return {
            inviteLink: `https://${Settings.mainDomain}/#/register-user?eco=${this.$route.params?.companyId}&ch=${this.$route.params?.id}&showCohortModal=true`,
            detailViewHeader: c.detailViewHeader,
            formGroup: cohortDetailForm,
            formData: {},
            opportunity: {
                title: "Opportunities",
                description:
                    "Now that you have real data back from potential customers, you can do an apples-to-apples comparison to figure out the most viable combination of target customer segments and the problems those people have.",
                items: [],
            },
            selectedTab: null,
            contacts: [],
            cohort: null,
            copyUrl: null,
            teams: [],
            people: [],
            moment,
            isShowPeople: false,
            isLoading: false,
            c,
            isUserSaving: false,
            isShowUser: false,
            isOpenUserRemove: false,
            selectedUser: null,
            user: null,
            inviteForm: {},
            recordPerPage: 10,
            recordActivePage: 1,
            pagination: {
                ventures: {
                    recordPerPage: 10,
                    recordActivePage: 1,
                },
                people: {
                    recordActivePage: 1,
                    recordPerPage: 10,
                },
                opportunities: {
                    recordActivePage: 1,
                    recordPerPage: 10,
                },
            },
            keyword: null,
            sortPeople: null,
            sortOpportunity: null,
        };
    },
    async created() {
        if (this.state.currentCompany?.id === this.$route.params?.companyId) {
            this.company = this.state.currentCompany;
        } else {
            const companies = await this.actions.company.getCompanies({
                where: { id: this.$route.params?.companyId },
                getValues: true,
                query: "companyDefault",
            });
            this.company = companies[0];
            this.actions.setCurrentCompany(this.company);
        }
        if (
            this.company &&
            this.company.programs &&
            this.company.programs.length > 0
        ) {
            this.formGroup[0].fields[1].options = JSON.stringify(
                this.company.programs.map((program) => {
                    return { id: program?.id, name: program?.name };
                })
            );
        } else {
            this.formGroup[0].fields[1].options = JSON.stringify([]);
        }

        this.selectedTab = this.subNavigationBar[0];
        const items = await this.actions.cohort.getCohorts({
            where: { id: this.$route.params?.id },
            getValues: true,
        });
        this.cohort = items[0];
        console.log(this.cohort, "cohort");
        if (this.state.group?.groups?.length === 0)
            this.actions.group.getGroups();
        this.formData.name = this.cohort?.name;
        this.formData.program = {
            id: this.cohort?.program?.id,
            name: this.cohort?.program?.name,
        };

        if (this.cohort?.startDate)
            this.formData.startDate = new Date(this.cohort?.startDate)
                .toISOString()
                .slice(0, 10);
        if (this.cohort?.endDate)
            this.formData.endDate = new Date(this.cohort?.endDate)
                .toISOString()
                .slice(0, 10);
        this.copyUrl = `https://${Settings.mainDomain}/#/register-user?eco=${this.company?.id}&ch=${this.cohort?.id}&showCohortModal=true`;
        const userSurveys = await this.actions.userSurvey.getUserSurveys({
            where: {
                isArchived_not: true,
            },
            getValues: true,
            all: true,
            fragments: `{id  opportunity {id isArchived} profile {id} survey {id targetCustomerUser {id isArchived}}}`,
        });
        this.cohort?.ventures?.map(async (v) => {
            let resp = 0;
            const opportunities = v.opportunities
                ?.filter((o) => o?.isArchived !== true)
                ?.sort((a, b) =>
                    a?.interviews?.filter((o) => o?.isArchived != true)
                        ?.length >
                    b?.interviews?.filter((o) => o?.isArchived != true)?.length
                        ? -1
                        : 1
                );
            opportunities
                ?.filter((o) => !o?.isArchived)
                ?.map((o) => {
                    let responses = userSurveys?.filter(
                        (u) => u?.opportunity?.id === o?.id
                    )?.length;
                    const oppIndex = [...this.opportunity.items].findIndex(
                        (item) => item?.id === o?.id
                    );
                    if (oppIndex === -1 && responses > 0) {
                        resp += responses;
                        this.opportunity.items?.push({
                            id: o?.id,
                            Opportunity: o?.name
                                ?.split(" ")
                                ?.slice(1, o?.name?.split(" ")?.legnth)
                                .join(" "),
                            Venture: v?.name,
                            Responses: responses,
                            Magnitude: {
                                out: o?.outOfMarketPercentage || 0,
                                low: o?.lowMarketPercentage || 0,
                                mid: o?.midMarketPercentage || 0,
                                hi: o?.hiMarketPercentage || 0,
                                magnitude: o?.magnitudeScore || 0,
                            },
                            Market: numeral(o?.adjustedMarket || 0).format(
                                "0,0"
                            ),
                        });
                    }
                });
            const users = [];
            v?.users?.map((u) => users?.push(`${u?.firstName} ${u?.lastName}`));
            this.teams?.push({
                title: v?.name,
                actionLabel:
                    json(v?.milestones)?.sort((a, b) =>
                        a?.sortOrder > b?.sortOrder ? -1 : 1
                    )?.[0]?.tagLabel || "Getting Started",
                id: v?.id,
                description: users?.join(", "),
                subtitles: [
                    {
                        icon: "MapIcon",
                        name: "Target Customers",
                        count:
                            v?.targetCustomerUsers?.filter(
                                (t) => !t?.isArchived
                            )?.length || 0,
                    },
                    {
                        icon: "UsersIcon",
                        name: "Contacts",
                        count:
                            v?.contacts?.filter((o) => !o?.isArchived)
                                ?.length || 0,
                    },
                    {
                        icon: "ExclamationIcon",
                        name: "Opportunities",
                        count:
                            v?.opportunities?.filter((o) => !o?.isArchived)
                                ?.length || 0,
                    },
                    {
                        name: "Interviews",
                        count: v?.interviews?.filter((o) => !o?.isArchived)
                            ?.length,
                        icon: "ChatAlt2Icon",
                    },
                    {
                        name: "Responses",
                        count: this.getResponses(userSurveys, v),
                        icon: "ChartBarIcon",
                    },
                ],
            });
        });
        this.cohort?.ventures?.map((v) => {
            // this.contacts?.push({
            //     id: u?.id,
            //     email: u?.email || "",
            //     groups: u?.groups || [],
            //     firstName: u?.firstName || "",
            //     lastName: u?.lastName || "",
            //     type: u?.groups?.[0]?.name || "",
            //     value: (u?.firstName || "") + " " + (u?.lastName || ""),
            // });
            v?.users?.map((u) => {
                this.people?.push({
                    id: u?.id,
                    Name:
                        upperFirst(u?.firstName) +
                        " " +
                        upperFirst(u?.lastName),
                    Email: u?.email,
                    Team: upperFirst(v?.name),
                    // Cohort: c?.name,
                    "Date Joined": moment(u?.createdAt).format("M/D/YYYY"),
                });
            });
        });
    },
    methods: {
        getResponses(userSurveys, venture) {
            let responses = 0;
            venture?.targetCustomerUsers
                ?.filter((t) => !t?.isArchived)
                ?.map((t) => {
                    responses +=
                        max(
                            Object.values(
                                mapValues(
                                    groupBy(
                                        userSurveys?.filter(
                                            (c) =>
                                                t?.id ===
                                                c?.survey?.targetCustomerUser
                                                    ?.id
                                        ),
                                        "opportunity.id"
                                    ),
                                    size
                                ),
                                "userSurveys FLEX"
                            )
                        ) || 0;
                });
            return responses;
        },
        getItems(items, type) {
            if (type === "people") {
                const data = [...this.getList(items)];
                if (!this.sortPeople) {
                    return data.slice(
                        (this.pagination.people.recordActivePage - 1) *
                            this.pagination.people.recordPerPage,
                        this.pagination.people.recordPerPage *
                            this.pagination.people.recordActivePage
                    );
                } else {
                    let list = [];
                    if (
                        this.sortPeople?.name === "Magnitude" &&
                        typeof items?.[0]?.Magnitude === "object"
                    ) {
                        list = data.sort((a, b) =>
                            a[this.sortPeople.name]?.magnitude >
                            b[this.sortPeople.name]?.magnitude
                                ? this.sortPeople?.sort
                                    ? 1
                                    : -1
                                : this.sortPeople?.sort
                                ? -1
                                : 1
                        );
                    } else if (this.sortPeople?.name === "Market") {
                        list = data.sort((a, b) =>
                            parseInt(
                                a[this.sortPeople.name]?.replace(/[^0-9]/gi, "")
                            ) >
                            parseInt(
                                b[this.sortPeople.name]?.replace(/[^0-9]/gi, "")
                            )
                                ? this.sortPeople?.sort
                                    ? 1
                                    : -1
                                : this.sortPeople?.sort
                                ? -1
                                : 1
                        );
                    } else {
                        list = data.sort((a, b) =>
                            a[this.sortPeople.name]?.toString()?.toLowerCase() >
                            b[this.sortPeople.name]?.toString()?.toLowerCase()
                                ? this.sortPeople?.sort
                                    ? 1
                                    : -1
                                : this.sortPeople?.sort
                                ? -1
                                : 1
                        );
                    }

                    return list.slice(
                        (this.pagination.people.recordActivePage - 1) *
                            this.pagination.people.recordPerPage,
                        this.pagination.people.recordPerPage *
                            this.pagination.people.recordActivePage
                    );
                }
            } else if (type === "opportunities") {
                const data = [...this.getList(items)];
                if (!this.sortOpportunity) {
                    return data.slice(
                        (this.pagination.opportunities.recordActivePage - 1) *
                            this.pagination.opportunities.recordPerPage,
                        this.pagination.opportunities.recordPerPage *
                            this.pagination.opportunities.recordActivePage
                    );
                } else {
                    let list = [];
                    if (
                        this.sortOpportunity?.name === "Magnitude" &&
                        typeof items?.[0]?.Magnitude === "object"
                    ) {
                        list = data.sort((a, b) =>
                            a[this.sortOpportunity.name]?.magnitude >
                            b[this.sortOpportunity.name]?.magnitude
                                ? this.sortOpportunity?.sort
                                    ? 1
                                    : -1
                                : this.sortOpportunity?.sort
                                ? -1
                                : 1
                        );
                    } else if (this.sortOpportunity?.name === "Market") {
                        list = data.sort((a, b) =>
                            parseInt(
                                a[this.sortOpportunity.name]?.replace(
                                    /[^0-9]/gi,
                                    ""
                                )
                            ) >
                            parseInt(
                                b[this.sortOpportunity.name]?.replace(
                                    /[^0-9]/gi,
                                    ""
                                )
                            )
                                ? this.sortOpportunity?.sort
                                    ? 1
                                    : -1
                                : this.sortOpportunity?.sort
                                ? -1
                                : 1
                        );
                    } else {
                        list = data.sort((a, b) =>
                            a[this.sortOpportunity.name]
                                ?.toString()
                                ?.toLowerCase() >
                            b[this.sortOpportunity.name]
                                ?.toString()
                                ?.toLowerCase()
                                ? this.sortOpportunity?.sort
                                    ? 1
                                    : -1
                                : this.sortOpportunity?.sort
                                ? -1
                                : 1
                        );
                    }

                    return list.slice(
                        (this.pagination.opportunities.recordActivePage - 1) *
                            this.pagination.opportunities.recordPerPage,
                        this.pagination.opportunities.recordPerPage *
                            this.pagination.opportunities.recordActivePage
                    );
                }
            } else {
                return (type === "people" ? this.getList(items) : items).slice(
                    (this.pagination[type].recordActivePage - 1) *
                        this.pagination[type].recordPerPage,
                    this.pagination[type].recordPerPage *
                        this.pagination[type].recordActivePage
                );
            }
        },
        getList(items) {
            return items?.filter((i) => {
                const values = [];
                Object.keys(i)
                    ?.filter((k) => k !== "id")
                    ?.map((k) => values?.push(i[k]));
                if (!this.keyword) {
                    return true;
                } else if (
                    this.keyword &&
                    values?.find((v) =>
                        v?.toLowerCase()?.includes(this.keyword?.toLowerCase())
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        async perPageChanged(count, type) {
            this.pagination[type].recordPerPage = count;
            this.pagination[type].recordActivePage = 1;
        },
        async pageChanged(page, type) {
            this.pagination[type].recordActivePage = page;
        },
        async onCopyUrl(url) {
            const { toClipboard } = useClipboard();
            try {
                await toClipboard(url);
                this.actions.alert.showSuccess({
                    message: "Copied the url to your clipboard",
                });
            } catch (e) {
                console.error(e);
            }
        },
        arrayToCsv(data) {
            const array = [Object.keys(data[0])].concat(data);

            return array
                .map((it) => {
                    return Object.values(it).toString();
                })
                .join("\n");
        },

        /* https://stackoverflow.com/a/68146412 */
        /* downloadBlob(csv, 'export.csv', 'text/csv;charset=utf-8;')*/
        downloadBlob(content, filename, contentType) {
            // Create a blob
            var blob = new Blob([content], { type: contentType });
            var url = URL.createObjectURL(blob);

            // Create a link to download it
            var pom = document.createElement("a");
            pom.href = url;
            pom.setAttribute("download", filename);
            pom.click();
        },
        onExport() {
            console.log(this.people, "people");
            const myLogs = this.arrayToCsv(this.people);
            this.downloadBlob(
                myLogs,
                "./people.csv",
                "text/csv;charset=utf-8;"
            );
        },
        inviteMemberData() {
            const data = [
                {
                    type: "autocomplete",
                    label: "Email Address",
                    placeholder: "Email Address",
                    options: [],
                    value: null,
                },
            ];
            // if (this.isShowUser) {
            //     if (this.user?.email) data[0].value = this.user?.email;
            //     if (this.user?.groups?.[0])
            //         data[1].selectedItem = this.user?.groups?.[0];
            // }
            this.company?.users?.map((u) => {
                data[0].options.push({
                    ...u,
                    name: u.email,
                    label: u.email,
                });
            });
            return data;
        },
        async onSendInvite(item) {
            console.log(item, "item ====");
            if (this.isLoading) return false;
            const emails = item[0]?.value;
            if (emails) {
                try {
                    this.isLoading = true;
                    const items = emails?.split(",");
                    const invites = [];
                    items.map((i) => {
                        invites.push({
                            email: i?.trim(),
                        });
                    });
                    await this.actions.user.inviteContact({
                        contacts: invites,
                        userId: this.state.currentUser?.id,
                        extraData: {
                            inviteLink: this.inviteLink,
                            ecosystemId: this.$route.params?.companyId,
                            ecosystemName: this.company?.name,
                            cohortName: this.cohort?.name,
                            programId: this.$route.params?.programId,
                            programName: this.cohort?.program?.name,
                            cohortId: this.$route.params?.id,
                            username: this.state.currentUser?.username,
                            fullName:
                                this.state.currentUser?.firstName +
                                " " +
                                this.state.currentUser?.lastName,
                            originPath: `https://${Settings.mainDomain}/#/${this.$route.fullPath}`,
                        },
                    });
                    this.actions.alert.showSuccess({
                        message: "Sent invitations successfully!",
                    });
                    this.isShowPeople = false;
                } catch (e) {
                    console.log(e);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async saveCohortForm() {
            if (this.isLoading) return false;
            this.isLoading = true;
            const params = {
                name: this.formData?.name,
            };
            if (this.formData?.startDate)
                params["startDate"] = this.formData?.startDate;
            if (this.formData?.endDate)
                params["endDate"] = this.formData?.endDate;
            if (this.formData?.program?.id)
                params["program"] = {
                    connect: { id: this.formData?.program?.id },
                };
            const response = await this.actions.saveFormData({
                mutation: "saveCohort",
                params,
                where: { id: this.cohort?.id },
            });
            if (response && response.id) {
                this.cohort = response;
                this.actions.alert.showSuccess({
                    message: "Cohort updated successfully",
                });
            }
            this.isLoading = false;
        },
        onClickVentureTeam(teamId) {
            this.$router.push({
                name: "Venture Dashboard",
                params: { ventureId: teamId },
            });
        },
        async handleUser(user) {
            console.log(this.inviteForm, "user");
            this.isUserSaving = true;
            // if (this?.user?.id) {
            //     const params = {
            //         email: user[0].value,
            //         groupId: user[1].selectedItem?.id,
            //         cohortId: this?.cohort?.id,
            //         userId: this?.user?.id,
            //     };
            //     const response = await this.actions.cohort.updateCohortUser(
            //         params
            //     );
            //     if (response?.updateCohortUser?.id) {
            //         this.actions.alert.showSuccess({
            //             message: "User updated successfully!",
            //         });
            //     }
            // } else {
            if (this.inviteForm["Email Address"]?.id) {
                const { saveCohort } = await this.actions.cohort.saveCohort({
                    where: { id: this.cohort?.id },
                    data: {
                        users: {
                            connect: [
                                { id: this.inviteForm["Email Address"]?.id },
                            ],
                        },
                    },
                });
                saveCohort?.users?.map((u) => {
                    this.contacts?.push({
                        id: u?.id,
                        email: u?.email || "",
                        groups: u?.groups || [],
                        firstName: u?.firstName || "",
                        lastName: u?.lastName || "",
                        type: u?.groups?.[0]?.name || "",
                        value: (u?.firstName || "") + " " + (u?.lastName || ""),
                    });
                });
                this.actions.alert.showSuccess({
                    message: "User added successfully!",
                });
            } else if (this.inviteForm["Email Address"]?.label) {
                // const params = {
                //     email: this.inviteForm['Email Address']?.label,
                //     cohortId: this?.cohort?.id,
                // };
                // const response = await this.actions.cohort.addUserToCohort(
                //     params
                // );
                // if (response?.addUserToCohort?.id) {
                //     const u = response?.addUserToCohort;
                //     this.contacts?.push({
                //         id: u?.id,
                //         email: u?.email || "",
                //         groups: u?.groups || [],
                //         firstName: u?.firstName || "",
                //         lastName: u?.lastName || "",
                //         type: u?.groups?.[0]?.name || "",
                //         value: (u?.firstName || "") + " " + (u?.lastName || ""),
                //     });
                //     this.actions.alert.showSuccess({
                //         message: "User added successfully!",
                //     });
                // }
                await this.actions.user.inviteContact({
                    contacts: [
                        { email: this.inviteForm["Email Address"]?.label },
                    ],
                    userId: this.state.currentUser?.id,
                    metadata: {
                        programId: this.$route.params?.programId,
                        companyId: this.$route.params?.companyId,
                        cohortId: this.$route.params?.id,
                        ug: "cohort-admin",
                    },
                });
            }

            // }
            this.isUserSaving = false;
            this.isShowUser = false;
            this.user = null;
        },
        async removeUserFromCohort(user) {
            this.isOpenUserRemove = false;
            const params = {
                userId: user?.id,
                cohortId: this?.cohort?.id,
            };
            const response = await this.actions.cohort.removeUserFromCohort(
                params
            );
            if (response?.removeUserFromCohort) {
                this.actions.alert.showSuccess({
                    message: "User removed successfully!",
                });
            }
            this.selectedUser = null;
        },
    },
};
</script>

<style scoped></style>
