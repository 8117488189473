<template>
	<div class="flex items-center justify-between w-full">
		<Text color="black" weight="bold" size="2xl" customClass="sm:text-2xl" :content="title"/>
		<div class="flex items-center space-x-3">
			<InputWithLeadingIcon v-if="isShowSearchBox" :placeholder="'Find a person'"
                    :icon="'SearchIcon'" class="hidden lg:block min-w-min" v-model:value="keyword" />
			<ButtonWithIcon v-if="btnText" :click="() => $emit('onClick')" :label="btnText" iconName="PlusIcon" variant="primary" leading size="md" class="h-10 sm:h-10"/>
		</div>
	</div>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import ButtonWithIcon from "../../Button/WithIcon/WithIcon.vue";
import InputWithLeadingIcon from "../../Inputs/InputWithLeadingIcon/InputWithLeadingIcon.vue";

export default {
	components: {
		Text,
		ButtonWithIcon,
		InputWithLeadingIcon
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		btnText: {
			type: String,
			default: ''
		},
		isShowSearchBox: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			keyword: null,
		}
	},
	watch: {
		keyword: function (val) {
			this.$emit('onSearch', val)
		}
	}

}
</script>
