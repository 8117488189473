<template>
  <Combobox as="div" class="flex flex-col flex-1" v-model="selectedItem" v-slot="{ open }">
    <ComboboxLabel v-if="label" class="block text-sm font-semibold text-gray-700">{{ label }}</ComboboxLabel>
    <div class="relative mt-1 w-full">
      <ComboboxInput :placeholder="placeholder" @blur="onBlur" id="multi-input"
        @click="() => { if (isAutoShow) isShow = true }"
        class="w-full rounded-md border border-gray-300 h-10 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
        @focus="() => { if (isAutoShow) isShow = true }" @change="query = $event.target.value" autocomplete="off"
        :display-value="(item) => item && item.label" />
      <Text v-if="helpText" size="xs" color="gray-400" custom-class="mt-1 italic" weight="normal" :content="helpText" />
      <div v-if="!isNotMark" class="absolute inset-y-0 right-0 px-2">
        <div class="flex items-center space-x-1">
          <Icon v-if="selectedItem && !isButton"
            @click.stop="() => { handleSelectFn(null); $emit('update:selectedValue', null) }" name="XIcon" size="5"
            color="gray-400" class="mt-2 cursor-pointer hover:text-gray-600" />
          <Icon v-if="isButton && get(filteredItems(), 'length') === 0 && get(query, 'length') > 0"
            @click.stop="() => { handleSelectFn({ name: query }); selectedItem = null; query = null; }" name="CheckIcon"
            size="5" color="green-400" class="mt-2 cursor-pointer hover:text-gray-600" />
          <ComboboxButton class="listData-center rounded-r-md focus:outline-none items-center space-x-1">
            <Icon name="SelectorIcon" size="5" color="gray-400" class="mt-2" />
          </ComboboxButton>
        </div>
      </div>
      <div v-show="(!isNotMark && isShow) || (isNotMark && isShow && get(filteredItems(), 'length') > 0)">
        <ComboboxOptions static as="div"
          class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ComboboxOption v-for="item in filteredItems()" :key="item && item.id" :value="item" as="template"
            v-slot="{ active, selected }">
            <div
              :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-primary-600 text-white' : 'text-gray-900']">
              <Text :color="item.id === 0 ? 'indigo-600' : active ? 'white' : 'gray-900'"
                :weight="selected ? 'semibold' : 'normal'" class="block truncate" :content="item && item.label" />
              <span v-if="selected" :class="['absolute inset-y-0 right-0 flex listData-center pr-4']">
                <Icon name="CheckIcon" size="5" :class="active ? 'white' : 'primary-600'" />
              </span>
            </div>
          </ComboboxOption>
          <!-- <ComboboxOption v-if="isButton" as="template" value="+ Add">
            <div :class="['relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900']">
              <Text :color="'indigo-600'" :weight="'semibold'" class="block truncate" :content="'+ Add'" />
            </div>
          </ComboboxOption> -->
        </ComboboxOptions>
      </div>

    </div>
  </Combobox>
</template>

<script>
import { ref } from 'vue'
import Icon from '../../atoms/Icons/Icons.vue'
import Text from '../../atoms/Text/Text.vue'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'
import { get } from 'lodash'
/**
 * - Use it to show an auto complete form
 */
export default {
  components: {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    Icon, Text
  },
  props: {
    /**
     * Use it for label
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Use it for list data
     */
    listData: {
      type: Array,
      default: () => []
    },
    /**
     * Use it for selecting action
     */
    handleSelectFn: {
      type: Function,
      default: () => { }
    },
    /**
     * Use it for changing relation
     */
    onChangeRelation: {
      type: Function,
      default: () => { }
    },
    placeholder: {
      type: String,
      default: ''
    },
    helpText: {
      type: String,
      default: null,
    },
    /**
 * used to set predefined value.
 */
    selectedValue: {
      type: Object,
      default: () => { },
    },
    isNotMark: {
      type: Boolean,
      default: false,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
    isAutoShow: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      query: ref(''),
      selectedItem: null,
      isShow: false,
      get,
    }
  },
  watch: {
    selectedItem: function (val) {
      this.handleSelectFn(val);
      this.$emit("update:selectedValue", val);
      // document.getElementById('multi-input').blur()
      if (this.isButton) {
        this.query = null;
        this.selectedItem = null;
      }
    },
    selectedValue: {
      handler(val) {
        this.selectedItem = val;
      },
      deep: true,
    },
    query: function (val) {
      console.log(val, 'val')
      if (this.filteredItems()?.length > 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      if (val) {
        if (!this.isButton) {
          this.handleSelectFn({ label: this.query });
          this.$emit("update:selectedValue", { label: this.query });
        }
      } else {
        if (!this.isButton) {
          this.handleSelectFn(null);
          this.$emit("update:selectedValue", null);
        }

      }
    }
  },
  methods: {
    onBlur() {
      setTimeout(() => {
        this.isShow = false;
      }, 100)
    },
    filteredItems() {
      let items = (!this.query ? this.listData : (
        this.listData.filter((item) => item.label?.toLowerCase().includes(this.query?.toLowerCase())))[0]?.id === 0
        ? this.listData.filter((item) => item.label?.toLowerCase().includes(this.query?.toLowerCase()))
        : [...this.listData.filter((item) => item.label?.toLowerCase().includes(this.query?.toLowerCase()))]
      ) || []
      return items;
    }
  }
}
</script>
