<template>
    <Template
        v-bind="{
            ...venture_teams,
            items: teams,
            toggleSlideover: () => {
                isShow = true;
            },
            onClickItem: (item) => onClickItem(item),
            emptyViewBinding: emptyViewBinding,
            searchText: searchText,
            onSearchTextChange: (text) => onSearchTextChange(text),
            isNotAdd,
            origTeams,
        }"
        @onSort="onSort"
        @onFilter="onFilter"
    />
    <InvitePeople
        v-bind="{
            ...c.invitePeople,
            isShow: isShow,
            isLoading,
            url: inviteLink,
        }"
        @onClose="() => (isShow = false)"
        @onSubmit="(item) => onSendInvite(item)"
    />
</template>

<script>
import Template from "../components/templates/VentureTeams/VentureTeams";
import { c } from "../components/constants";
import InvitePeople from "@/components/organisms/Modals/InvitePeople/InvitePeople";
import { Settings } from "../../settings";
import { json } from "overmind";
import { get, upperFirst, groupBy, mapValues, size, max } from "lodash";

export default {
    components: {
        InvitePeople,
        Template,
    },
    props: {
        programId: {
            type: String,
            default: "",
        },
        isNotAdd: {
            type: Boolean,
            default: false,
        },
        ventures: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            c,
            teams: [],
            origTeams: [],
            isShow: false,
            isLoading: false,
            emptyViewBinding: {
                description: "No ventures teams to display just yet!",
            },
            searchText: "",
            inviteLink: `https://${Settings.mainDomain}/#/register-user?eco=${this.$route.params?.companyId}`,
            sort: null,
            filter: null,
            venture_teams: c.venture_teams,
        };
    },
    watch: {
        sort: function (val) {
            this.teamList();
        },
        filter: function (val) {
            this.teamList();
        },
        searchText: function (val) {
            this.teamList();
        },
    },
    async created() {
        if (this.state.currentCompany?.id === this.$route.params?.companyId) {
            this.company = this.state.currentCompany;
        } else {
            const companies = await this.actions.company.getCompanies({
                where: { id: this.$route.params?.companyId },
                getValues: true,
                query: "companyDefault",
            });
            console.log(companies);
            this.company = companies[0];
            this.actions.setCurrentCompany(this.company);
            console.log(this.company, "company");
        }
        console.log(this.company, "company");
        let ventures = [];
        if (this.programId) {
            ventures = this.ventures;
            console.log(ventures, "ventures");
        } else {
            ventures = this.company?.ventures;
            this.company?.programs?.map((p) =>
                p?.cohorts?.map(
                    (c) =>
                        (ventures = [
                            ...ventures,
                            ...c?.ventures?.filter(
                                (v) => !ventures?.find((vv) => vv?.id === v?.id)
                            ),
                        ])
                )
            );
        }
        console.log(json(ventures), "ventures");
        const userSurveys = await this.actions.userSurvey.getUserSurveys({
            where: {
                isArchived_not: true,
            },
            getValues: true,
            all: true,
            fragments: `{id  opportunity {id isArchived} profile {id} survey {id targetCustomerUser {id isArchived}}}`,
        });
        console.log(userSurveys, "userSurveys");
        ventures?.map((v) => {
            // let responses = 0;
            // v.opportunities
            //     ?.filter((o) => !o?.isArchived)
            //     ?.map((o) => {
            //         o.surveyOpportunities?.map((so) => {
            //             responses += so?.responses?.length || 0;
            //         });
            //     });
            const users = [];
            v?.users?.map((u) => users?.push(`${u?.firstName} ${u?.lastName}`));
            this.teams?.push({
                ...v,
                title: v?.name,
                actionLabel:
                    json(v?.milestones)?.sort((a, b) =>
                        a?.sortOrder > b?.sortOrder ? -1 : 1
                    )?.[0]?.tagLabel || "Getting Started",
                id: v?.id,
                description: users?.join(", "),
                subtitles: [
                    {
                        icon: "MapIcon",
                        name: "Target Customers",
                        count:
                            v?.targetCustomerUsers?.filter(
                                (t) => !t?.isArchived
                            )?.length || 0,
                    },
                    {
                        icon: "UsersIcon",
                        name: "Contacts",
                        count:
                            v?.contacts?.filter((o) => !o?.isArchived)
                                ?.length || 0,
                    },
                    {
                        icon: "ExclamationIcon",
                        name: "Opportunities",
                        count:
                            v?.opportunities?.filter((o) => !o?.isArchived)
                                ?.length || 0,
                    },
                    {
                        name: "Interviews",
                        count: v?.interviews?.filter((o) => !o?.isArchived)
                            ?.length,
                        icon: "ChatAlt2Icon",
                    },
                    {
                        name: "Responses",
                        count: this.getResponses(userSurveys, v),
                        icon: "ChartBarIcon",
                    },
                ],
            });
        });
        this.origTeams = this.teams;
        await this.actions.milestone.getMilestones();
        console.log(json(this.teams), "teams");
        this.venture_teams.listHeader.filter.items[0].children =
            this.state.milestone.milestones;
        this.venture_teams.listHeader.filter.items[1].children =
            this.company.programs;
        let cohorts = [];
        this.company.programs?.map(
            (p) => (cohorts = [...cohorts, ...p?.cohorts])
        );
        this.venture_teams.listHeader.filter.items[2].children = cohorts;
    },
    methods: {
        getResponses(userSurveys, venture) {
            let responses = 0;
            venture?.targetCustomerUsers
                ?.filter((t) => !t?.isArchived)
                ?.map((t) => {
                    responses +=
                        max(
                            Object.values(
                                mapValues(
                                    groupBy(
                                        userSurveys?.filter(
                                            (c) =>
                                                t?.id ===
                                                c?.survey?.targetCustomerUser
                                                    ?.id
                                        ),
                                        "opportunity.id"
                                    ),
                                    size
                                ),
                                "userSurveys FLEX"
                            )
                        ) || 0;
                });
            return responses;
        },
        teamList() {
            let items = [...this.origTeams] || [];
            console.log(items, "items");
            if (this.searchText.trim()) {
                items = items.filter((item) =>
                    item?.title
                        .toLowerCase()
                        .includes(this.searchText.trim().toLowerCase())
                );
            }
            if (this.filter?.parent?.name === "Milestone") {
                if (this.filter?.child?.name) {
                    items = items?.filter((t) =>
                        this.company?.programs?.find((p) =>
                            p?.cohorts?.find((c) =>
                                c?.ventures
                                    ?.find((v) => v?.id === t?.id)
                                    ?.milestones?.find(
                                        (m) =>
                                            m?.name === this.filter?.child?.name
                                    )
                            )
                        )
                    );
                } else {
                    items = items?.filter((t) => t?.milestones);
                }
            } else if (this.filter?.parent?.name === "Program") {
                if (this.filter?.child?.name) {
                    items = items.filter((v) =>
                        this.company?.programs
                            ?.find((p) => p?.name === this.filter?.child?.name)
                            ?.cohorts?.find((c) =>
                                c?.ventures?.find((cv) => cv?.id === v?.id)
                            )
                    );
                }
                items = [...items]; // TODO: update logic later
            } else if (this.filter?.parent?.name === "Cohort") {
                if (this.filter?.child?.name) {
                    items = items?.filter((t) =>
                        this.company?.programs?.find(
                            (p) =>
                                p?.cohorts?.find((c) =>
                                    c?.ventures?.find((v) => v?.id === t?.id)
                                )?.name === this.filter?.child?.name
                        )
                    );
                } else {
                    items = items?.filter((t) => t?.cohorts);
                }
            } else if (this.filter?.parent?.name === "Clear") {
                items = items;
                this.filter = null;
            }

            if (this.sort?.parent?.name === "Opportunities") {
                items = items.sort((a, b) =>
                    a?.opportunities?.filter((o) => !o?.isArchived)?.length >
                    b?.opportunities?.filter((o) => !o?.isArchived)?.length
                        ? -1
                        : 1
                );
            } else if (this.sort?.parent?.name === "Contacts") {
                items = items.sort((a, b) =>
                    a?.contacts?.filter((o) => !o?.isArchived)?.length >
                    b?.contacts?.filter((o) => !o?.isArchived)?.length
                        ? -1
                        : 1
                );
            } else if (this.sort?.parent?.name === "Interviews") {
                items = items.sort((a, b) =>
                    a?.interviews?.filter((o) => !o?.isArchived)?.length >
                    b?.interviews?.filter((o) => !o?.isArchived)?.length
                        ? -1
                        : 1
                );
            } else if (this.sort === "Target Customers") {
                items = items.sort((a, b) =>
                    a?.targetCustomerUsers?.length >
                    b?.targetCustomerUsers?.length
                        ? -1
                        : 1
                );
            } else if (this.sort?.parent?.name === "Created Date") {
                items = items.sort((a, b) =>
                    a?.createdAt > b?.createdAt ? -1 : 1
                );
            } else if (this.sort?.parent?.name === "Name") {
                items = items.sort((a, b) => (a?.name > b?.name ? 1 : -1));
            } else if (this.sort?.parent?.name === "Clear") {
                items = items;
                this.sort = null;
            }
            console.log(items, "items");
            this.teams = items;
        },
        onSort(sort) {
            this.sort = sort;
            console.log(this.sort, "sort");
        },
        onFilter(filter) {
            this.filter = filter;
            console.log(this.filter, "filter");
        },
        async onSendInvite(item) {
            if (this.isLoading) return false;
            console.log(item, "item ====");
            const emails = item[0]?.value;
            if (emails) {
                try {
                    this.isLoading = true;
                    const items = emails?.split(",");
                    const invites = [];
                    items.map((i) => {
                        invites.push({
                            email: i?.trim(),
                        });
                    });
                    await this.actions.user.inviteContact({
                        contacts: invites,
                        userId: this.state.currentUser?.id,
                        extraData: {
                            inviteLink: this.inviteLink,
                            ecosystemId: this.$route.params?.companyId,
                            ecosystemName: this.company?.name,
                            originPath: `https://${Settings.mainDomain}/#/${this.$route.fullPath}`,
                            username: this.state.currentUser?.username,
                            fullName:
                                this.state.currentUser?.firstName +
                                " " +
                                this.state.currentUser?.lastName,
                        },
                    });
                    this.actions.alert.showSuccess({
                        message: "Sent invitations successfully!",
                    });
                    this.isShow = false;
                } catch (e) {
                    console.log(e);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        onClickItem(venture) {
            var query = {};
            if (this?.programId) query["program"] = this?.programId;
            this.$router.push({
                name: "Venture Dashboard",
                params: {
                    ventureId: venture?.id,
                },
                query,
            });
        },
        onSearchTextChange(searchText) {
            this.searchText = searchText;
        },
    },
};
</script>

<style scoped></style>
