<template>
	<div ref="base" :class="customClass">
		<div class="sm:hidden border-none sm:border rounded-lg">
			<label for="tabs" class="sr-only">Select a tab</label>
			<!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
			<div class="flex items-center relative w-full">
				<Select :items="tabs" v-model:selectedItem="selectedTab" customClass="w-full" />
			</div>
		</div>
		<div class="hidden sm:block">
			<div>
				<nav class="relative flex" aria-label="Tabs">
					<button v-for="tab in tabs" :ref="tab.name" @click="getPos(tab)" :key="tab.name" :class="tab?.name === selectedTab?.name && 'bg-indigo-50'"
						class="z-10 inline-flex items-center px-3 py-2 text-sm font-medium group space-x-1 border-b border-gray-100 rounded"
						:aria-current="tab.current ? 'page' : undefined">
						<Icon v-if="!hideIcon" :name="tab.icon" size="5"
							:color="selectedTab.name === tab.name ? 'primary-900' : 'primary-700'" />
						<Text :content="tab.name" :color="selectedTab.name === tab.name ? 'primary-900' : 'primary-700'" />
						<Badge v-if="tab.count" :isRounded="true" class="inline-block ml-auto" px="3" py="0.5" size="xs"
							weight="medium" :bgColor="selectedTab.name === tab.name ? 'primary-100' : 'gray-200'"
							:textColor="selectedTab.name === tab.name ? 'primary-700' : 'gray-500'" :label="tab.count.toString()" />
					</button>
					<!-- <span class="absolute z-0 transition-all duration-300 bg-indigo-50 rounded"
						:style="{ left: tabBgLeft + 'px', width: tabBgWidth + 'px', height: tabBgHeight + `px` }"></span> -->
				</nav>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '../../atoms/Icons/Icons.vue'
import Text from '../../atoms/Text/Text.vue'
import Badge from '../../molecules/Badge/Basic/Basic.vue'
import Select from '../../molecules/Selects/CustomWithIconAndBadge/CustomWithIconAndBadge.vue'

/**
 * - Use it when show tabs with underline and icons
 * - tabs is required. default is empty array
 */
export default {
	name: "SubnavigarionBar",

	components: {
		Icon,
		Text,
		Badge,
		Select
	},

	props: {
		/**
		 * the list of tabs
		 */
		tabs: {
			type: Array,
			default: () => [],
			required: true,
		},
		customClass: {
			type: String,
			default: ''
		},
		hideIcon: {
			type: Boolean,
			default: false
		},
		currentTab: {
			type: Object,
			default: () => { }
		}
	},

	methods: {
		getPos(el) {
			const baseX = this.$refs['base'].getBoundingClientRect().left;
			const left = this.$refs[`${el.name}`][0].getBoundingClientRect().left;
			const width = this.$refs[`${el.name}`][0].getBoundingClientRect().width;
			const height = this.$refs[`${el.name}`][0].getBoundingClientRect().height;
			this.tabBgLeft = left - baseX;
			this.tabBgWidth = width;
			this.tabBgHeight = height;
			this.selectedTab = el;
		}
	},
	watch: {
		selectedTab: {
			handler(val) {
				console.log(val, 'val')
				this.getPos(val)
				this.$emit('onSelect', val)
			},
			deep: true,
		},
		currentTab: {
			handler(val) {
				this.selectedTab = val;
				this.getPos(val)
			},
			deep: true
		}
	},

	mounted() {
		setTimeout(() => {
			this.$refs.Details[0].click();
		}, 100);
	},

	data() {
		return {
			tabBgLeft: 0,
			tabBgWidth: 0,
			tabBgHeight: 0,
			selectedTab: this.currentTab || this.tabs[0]
		}
	},
};
</script>
