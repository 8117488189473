<template>
    <SideOver v-if="isLoad" :title="title" :is-show="isShow" :label="content" :submitBtnText="'Send Invitation'"
        @onClose="onClose" @onSubmit="() => onSubmit()" :isLoading="isLoading">
        <div class="flex flex-col h-full flex-1">
            <div class="flex flex-col flex-1 p-6 space-y-4">
                <div v-for="item in formGroup">
                    <Textarea :label="item.label" :placeholder="item.placeholder" v-model:value="item.value" />
                </div>
            </div>
            <div class="flex flex-none bg-gray-100 px-6 py-4 w-full">
                <Input v-model:value="link" :rounded="true" :type="'text'" :customClass="''"
                    :label="'If you\'d like to email these people yourself, you can always send them the link below'"
                    :is-button="true" :placeholder="''"  @onCopy="onCopy" />
            </div>
        </div>
    </SideOver>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Input from "../../../molecules/Inputs/Component/Component.vue";
import Select from "../../../molecules/Selects/Component/Component.vue";
import Button from "../../../atoms/Button/Button.vue";
import SideOver from "../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue";
import Textarea from "../../../molecules/Textareas/Simple/Simple";
import useClipboard from 'vue-clipboard3'

export default {
    components: {
        Text,
        Icon,
        Input,
        Select,
        Button,
        SideOver,
        Textarea,
    },
    emits: ['onSubmit'],
    props: {
        isShow: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: "",
        },
        content: {
            type: String,
            default: "",
        },
        formGroup: {
            type: Array,
            default: () => [],
        },
        buttons: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: true,
        },
        submitBtnText: {
            type: String,
            default: "",
        },
        list: {
            type: Array,
            default: () => [],
        },
        url: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            link: this.url,
            isLoad: false,
        }
    },
    watch: {
        url: function (val) {
            this.link = val;
        },
        isShow: function (val) {
            console.log(val, 'val')
            if (val) {
                setTimeout(() => (this.isLoad = true), 100);
            } else {
                setTimeout(() => (this.isLoad = false), 700);
            }
        },
    },
    methods: {
        async onCopy() {
            const { toClipboard } = useClipboard()
            try {
                await toClipboard(this.link)
                this.actions.alert.showSuccess({ message: 'Copied to your clipboard' })
            } catch (e) {
                console.error(e)
            }
        },
        onSubmit() {
            this.$emit("onSubmit", this.formGroup);
        },
        onClose() {
            this.$emit('onClose');
            setTimeout(() => {
                this.isLoad = false;
            }, 700)
        }
    },
};
</script>
