<template>
  <div class="border bg-white cursor-pointer" @click="$emit('onClick')" :class="customClass">
    <div class="p-4 lg:p-6">
      <div class="inline-flex flex-col lg:flex-row justify-between w-full items-start lg:items-center">
        <div>
          <h2 class="text-2xl font-medium tracking-tight text-gray-700 mb-3 lg:mb-0">
            {{ title }}&nbsp;
          </h2>
        </div>

        <Badge v-if="actionLabel" :is-rounded="true" text-size="xs" px="3" py="2" bg-color="gray-400" text-color="white"
          :label="actionLabel" text-weight="medium" />
      </div>
      <Text v-if="description" size="sm" color="gray-700" :content="description" custom-class="w-full mt-0.5 truncate" />
      <div class="hidden lg:inline-flex flex-row w-full mt-2 overflow-x-auto">
        <div class="flex items-center mr-5" v-for="item in subtitles">
          <Icons :name="item.icon" size="4" color="gray-500" hover-text-color="gray-700" />
          <p v-if="item.name" class="text-ellipsis overflow-hidden whitespace-nowrap ml-1 text-sm"
            :class="[item.count !== undefined ? 'mr-2' : '']">
            {{ item.name }}
          </p>
          <!--                    <Badge v-if="item.count" :is-rounded="true" text-size="sm" px="2" py="0" bg-color="gray-400"-->
          <!--                           text-color="black" :label="item.count" />-->
          <span v-if="item.count !== undefined" class="text-sm">{{ item.count }}</span>
        </div>
      </div>
    </div>
    <div class="lg:hidden px-4 lg:px-8 pb-8">
      <table class="border w-full mobile-bordered-table">
        <tbody>
          <template v-for="item in subtitles">
            <tr v-if="item.count !== undefined">
              <td class="px-4 py-2">
                <p class="inline-flex items-center text-xs">
                  <Icons :name="item.icon" size="4" color="gray-500" hover-text-color="gray-700" />
                  <span class="pl-2">{{ item.name }}</span>
                </p>
              </td>
              <td class="px-4 py-2 text-xs">{{ item.count }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Badge from "../../Badge/Basic/Basic.vue";
import Dropdown from "../../Dropdown/Dropdown/Dropdown.vue";
import Link from "../../../atoms/Link/Link.vue";
import Icons from "../../../atoms/Icons/Icons.vue";
import Text from '../../../atoms/Text/Text'

export default {
  name: "DetailViewHeader",
  components: {
    Icons,
    Link,
    Badge,
    Dropdown,
    Text
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    actionLabel: {
      type: String,
      default: "",
    },
    subtitles: {
      type: Array,
      default: () => [],
    },
    customClass: {
      type: String,
      default: ""
    }
  },
};
</script>


<style>
.text-ellipsis {
  text-overflow: ellipsis;
}

.survey-table td,
.survey-table th {
  padding: 10px;
}

.mobile-bordered-table>tbody>tr>td {
  border-left: 1px solid #e4e4e7;
}

.mobile-bordered-table>tbody>tr>td:first-child {
  border-left: 0;
}
</style>
