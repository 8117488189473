<template>
	<div class="cursor-pointer border flex flex-col sm:flex-row items-start bg-white" :class="customClass"
		@click="$emit('onClick')">
		<div class="w-full sm:w-40 h-auto sm:h-40 bg-gray-200 flex justify-center items-center mt-0 sm:mt-0">
			<Icons v-if="!imgSrc" name="PhotographIcon" size="10" color="gray-400" hover-text-color="gray-400" />
			<img v-if="imgSrc" :src="imgSrc" class="w-full h-full" />
		</div>
		<div class="flex-1 px-3 sm:px-8 text-left mt-4">
			<h2 class="text-2xl font-medium tracking-tight text-gray-900">
				{{ title }}&nbsp;
			</h2>
			<p class="mt-2">{{ desc }}</p>
		</div>
		<div
			class="flex self-start px-3 sm:px-0 mt-4 flex-row sm:flex-col h-full items-center sm:items-end sm:mr-6 my-2 space-x-2">
			<span v-for="action in items" class="mb-2">
				<Badge :is-rounded="true" text-size="sm" px="3" py="2" bg-color="gray-400" text-color="white"
					:label="action && action.label" icon-size="6" :icon-name="action && action.icon" :is-left="true" />
			</span>
		</div>
	</div>
</template>

<script>
import Badge from "../../Badge/Basic/Basic.vue";
import Dropdown from "../../Dropdown/Dropdown/Dropdown.vue";
import Link from "../../../atoms/Link/Link.vue";
import Icons from "../../../atoms/Icons/Icons.vue";

export default {
	name: "DetailViewHeader",
	components: {
		Icons,
		Link,
		Badge,
		Dropdown
	},
	props: {
		title: {
			type: String,
			default: "",
		},
		imgSrc: {
			type: String,
			default: "",
		},
		desc: {
			type: String,
			default: "",
		},
		items: {
			type: Array,
			default: () => [],
		},
		customClass: {
			type: String,
			default: ""
		}
	},
};
</script>
