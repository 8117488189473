
<template>
  <Listbox v-model="selectedValue" as="div" class="w-full">
    <div class="relative mt-1">
      <ListboxButton
        class="relative w-full h-10 py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
        <span class="z-10 flex justify-between items-center text-sm font-medium group space-x-1">
          <div class="flex items-center space-x-2">
            <Icon :name="selectedValue.icon" size="5" :color="'gray-600'" />
            <Text :content="selectedValue.name" :color="'gray-600'" />
          </div>
          <Badge v-if="selectedValue.count" :isRounded="true" class="inline-block ml-auto" px="3" py="0.5" size="xs"
            weight="medium" :bgColor="'gray-100'" :textColor="'gray-600'" :label="selectedValue.count.toString()" />
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
          <Icon name="SelectorIcon" color="gray-400" size="5" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <ListboxOptions
          :class="`absolute ${customClass} z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`">
          <ListboxOption v-for="(item, key) in items" :key="key" v-slot="{ active, selected }" as="template" :value="item">
            <li @click="() => {selectedItem=item}"
              :class="[
              active ? 'text-white bg-primary-600' : 'text-gray-900', 'cursor-default w-full select-none flex items-center justify-between relative py-2 pl-3 pr-9']">
              <div class="flex items-center space-x-2">
                <Icon :name="item.icon" size="5" :color="active ? 'white' : 'gray-600'" />
                <Text :content="item.name" :color="active ? 'white' : 'gray-600'" />
              </div>
              <Badge v-if="item.count" :isRounded="true" class="inline-block ml-auto mr-3" px="3" py="0.5" size="xs"
                weight="medium" :bgColor="'gray-100'" :textColor="'gray-600'" :label="item.count.toString()" />
              <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4">
                <Icon name="CheckIcon" size="5" :color="`${active ? 'white' : 'primary-600'}`" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";
import Badge from '../../../molecules/Badge/Basic/Basic.vue'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    Icon,
    Text,
    Badge,
  },
  props: {
    customClass: {
      type: String,
      defalut: ""
    },
    /**
     * items to show in select
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * used to set predefined value.
     */
    selectedItem: {
      type: Object,
      default: () => { },
    },
  },

  computed: {
    selectedValue: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        console.log(newValue);
        this.$emit("update:selectedItem", newValue);
      },
    },
  },
};
</script>
