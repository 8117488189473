<template>
    <div class="w-full">
        <div v-if="!displayTableOnly" class="w-full sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <Text
                    size="xl"
                    weight="semibold"
                    color="gray-900"
                    :content="title"
                />
                <Text
                    size="sm"
                    color="gray-700"
                    :content="description"
                    class="mt-2"
                />
            </div>
            <div v-if="btnText" class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Button
                    :content="btnText"
                    variant="primary"
                    :onClick="onClick"
                />
            </div>
        </div>
        <div class="flex flex-col w-full mt-px">
            <div class="w-full">
                <div v-if="list.length > 0" class="min-w-full align-middle">
                    <div class="md:rounded-lg w-full overflow-x-scroll">
                        <table class="w-full">
                            <thead
                                v-if="displayHeaders"
                                class="bg-gray-50 divide-y divide-gray-300"
                            >
                                <tr>
                                    <th
                                        v-for="(item, id) in headers"
                                        :key="id"
                                        scope="col"
                                        class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 whitespace-nowrap"
                                    >
                                        <div
                                            class="flex justify-start items-center group"
                                        >
                                            {{ item.label }}
                                            <div
                                                v-if="
                                                    sortFields?.includes(
                                                        item?.label
                                                    )
                                                "
                                                class="ml-4 flex flex-col opacity-0 group-hover:opacity-100"
                                            >
                                                <Icon
                                                    @click="
                                                        () => onSort(item, id)
                                                    "
                                                    :name="
                                                        item.sort
                                                            ? 'ChevronDownIcon'
                                                            : 'ChevronUpIcon'
                                                    "
                                                    custom-class="cursor-pointer group-hover:text-gray-400"
                                                    size="5"
                                                    color="gray-50"
                                                    hoverTextColor="gray-300"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr
                                    v-for="(item, id) in list"
                                    :key="id"
                                    @click="$emit('onClickItem', item, id)"
                                >
                                    <td
                                        v-for="(i, index) in headers"
                                        :key="index"
                                        class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                        :class="isClickable && 'cursor-pointer'"
                                    >
                                        <div
                                            class="flex items-center"
                                            v-if="
                                                i.name === 'Magnitude' &&
                                                typeof item[i.name] === 'object'
                                            "
                                        >
                                            <Badge
                                                :is-rounded="true"
                                                text-size="sm"
                                                px="2"
                                                py="0"
                                                :bg-color="
                                                    getBgColor(i?.name, item)
                                                "
                                                text-color="black"
                                                :label="
                                                    get(
                                                        item[i.name],
                                                        'magnitude'
                                                    )?.toString()
                                                "
                                            />
                                            <div class="progress-bar ml-3">
                                                <span
                                                    class="bg-gray-400"
                                                    :style="{
                                                        width:
                                                            get(
                                                                item[i.name],
                                                                'out'
                                                            ) + '%',
                                                    }"
                                                ></span>
                                                <span
                                                    class="bg-orange-400"
                                                    :style="{
                                                        width:
                                                            get(
                                                                item[i.name],
                                                                'low'
                                                            ) + '%',
                                                    }"
                                                ></span>
                                                <span
                                                    class="bg-amber-300"
                                                    :style="{
                                                        width:
                                                            get(
                                                                item[i.name],
                                                                'mid'
                                                            ) + '%',
                                                    }"
                                                ></span>
                                                <span
                                                    class="bg-green-500"
                                                    :style="{
                                                        width:
                                                            get(
                                                                item[i.name],
                                                                'hi'
                                                            ) + '%',
                                                    }"
                                                ></span>
                                            </div>
                                        </div>
                                        <Badge
                                            v-else-if="
                                                i.name === 'Magnitude' &&
                                                isShowMagnitude
                                            "
                                            :label="
                                                item[i.name] ||
                                                (
                                                    item[i.name] &&
                                                    item[i.name].magnitudeScore
                                                )?.toString()
                                            "
                                            px="3"
                                            py="1"
                                            :bg-color="getBgColor(i.name, item)"
                                            custom-class="rounded-full"
                                            text-color="white"
                                            text-weight="md"
                                        />
                                        <div
                                            :class="
                                                i?.name === 'Team' &&
                                                isBoldTeam &&
                                                'font-bold'
                                            "
                                            v-else-if="
                                                i.name !== 'role' &&
                                                i.name !== 'Relationship' &&
                                                i.name !== 'Status' &&
                                                i.name !== 'Mag' &&
                                                i.name !== 'Magnitude'
                                            "
                                            @click="
                                                () =>
                                                    i.name === 'Target customer'
                                                        ? $emit(
                                                              'onClickTargetCustomer'
                                                          )
                                                        : {}
                                            "
                                        >
                                            {{ item && item[i.name] }}
                                        </div>
                                        <Badge
                                            v-else-if="
                                                item[i.name] !== undefined ||
                                                item[i.name] !== null
                                            "
                                            :label="
                                                item[i.name] ||
                                                (
                                                    item[i.name] &&
                                                    item[i.name].magnitudeScore
                                                )?.toString()
                                            "
                                            px="3"
                                            py="1"
                                            :bg-color="getBgColor(i.name, item)"
                                            custom-class="rounded-full"
                                            text-color="white"
                                            text-weight="md"
                                        />
                                    </td>
                                    <td
                                        class="sticky right-0 relative py-4 pl-3 pr-2 text-right whitespace-nowrap sm:pr-6 w-20 h-full bg-white"
                                    >
                                        <Text
                                            v-if="linkText"
                                            class="absolute -mt-2 border-b border-red-500 cursor-pointer right-6 w-fit"
                                            :content="linkText"
                                            color="red-500"
                                            hoverColor="red-600"
                                            size="sm"
                                            weight="medium"
                                            @click="
                                                $emit('onClickLink', item, id)
                                            "
                                        />
                                        <slot
                                            name="multiselect"
                                            :id="id"
                                            :selectedItem="selectedItem"
                                            :setSelectedItem="
                                                (item) => (selectedItem = item)
                                            "
                                        ></slot>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    v-else-if="empty"
                    class="flex justify-center items-center w-full mt-4"
                >
                    <Empty v-bind="empty" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../../atoms/Button/Button.vue";
import Link from "../../../atoms/Link/Link.vue";
import Text from "../../../atoms/Text/Text.vue";
import { computed } from "vue";
import { capitalize } from "lodash";
import Empty from "../../EmptyState/Simple/Simple.vue";
import Badge from "../../../molecules/Badge/Basic/Basic.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import { get } from "lodash";

/**
 * - Use it to show a simple table
 */
export default {
    components: {
        Button,
        Link,
        Text,
        Empty,
        Badge,
        Icon,
    },
    data() {
        return {
            /**
             * Generate dynamic headers according to list item
             */
            get,
            headers: [],
            modalView: false,
            selectedItem: null,
            origList: [],
        };
    },
    methods: {
        onSort(header, i) {
            this.headers
                .filter((h) => h?.name !== header?.name)
                ?.map((h) => (h.sort = null));
            this.headers[i] = {
                ...header,
                sort: !header?.sort,
            };
            this.$emit('onSort', this.headers[i])
        },
        getSorted(items) {
            const header = this.headers?.find((h) => h?.sort !== null);
            if (header) {
                if (
                    header?.name === "Magnitude" &&
                    typeof this.list?.[0]?.Magnitude === "object"
                ) {
                    return items.sort((a, b) =>
                        a[header.name]?.magnitude > b[header.name]?.magnitude
                            ? header?.sort
                                ? 1
                                : -1
                            : header?.sort
                            ? -1
                            : 1
                    );
                } else if (header?.name === "Market") {
                    return items.sort((a, b) =>
                        parseInt(a[header.name]?.replace(/[^0-9]/gi, "")) >
                        parseInt(b[header.name]?.replace(/[^0-9]/gi, ""))
                            ? header?.sort
                                ? 1
                                : -1
                            : header?.sort
                            ? -1
                            : 1
                    );
                } else {
                    return items.sort((a, b) =>
                        a[header.name]?.toLowerCase() >
                        b[header.name]?.toLowerCase()
                            ? header?.sort
                                ? 1
                                : -1
                            : header?.sort
                            ? -1
                            : 1
                    );
                }
            }
            return this.origList;
        },
        getBgColor(name, item) {
            if (name === "Magnitude") {
                const value = item[name]?.magnitude || item[name];
                if (value < 1) {
                    return "gray-400";
                } else if (value >= 1 && value < 25) {
                    return "orange-400";
                } else if (value >= 25 && value < 50) {
                    return "amber-300";
                } else if (value >= 50) {
                    return "green-400";
                }
            } else {
                return "primary-500";
            }
        },
    },
    props: {
        /**
         * Use it for table list
         */
        list: {
            type: Array,
            default: () => [],
        },
        /**
         * Use it for table title
         */
        title: {
            type: String,
            default: "",
        },
        /**
         * Use it for table description
         */
        description: {
            type: String,
            default: "",
        },
        /**
         * Use it for button text
         */
        btnText: {
            type: String,
            default: "",
        },
        /**
         * Use it for button text
         */
        linkText: {
            type: String,
            default: "",
        },
        /**
         * Use it for button action
         */
        onClick: {
            type: Function,
            default: () => {},
        },
        /**
         * Use it for header display
         */
        displayHeaders: {
            type: Boolean,
            default: true,
        },
        /**
         * Use it for table display only
         */
        displayTableOnly: {
            type: Boolean,
            default: false,
        },
        empty: {
            type: Object,
            default: () => {},
        },
        onClickItem: {
            type: Function,
            default: () => {},
        },
        isShowMagnitude: {
            type: Boolean,
            default: false,
        },
        isBoldTeam: {
            type: Boolean,
            default: false,
        },
        isClickable: {
            type: Boolean,
            default: false,
        },
        sortFields: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        // list: function (val) {

        // },
        list: {
            handler(val) {
                if (this.list?.length > 0 && this.list?.length !== this.origList?.length) {
                    this.origList = [...this.list];
                    let items = [];
                    Object.keys(this.list[0])
                        .filter(
                            (k) =>
                                k !== "id" &&
                                k !== "opportunityId" &&
                                k !== "ventureId" &&
                                k !== "isArchived"
                        )
                        .map((i) => {
                            let str = i.split(/(?=[A-Z])/);
                            str = str.join(" ");
                            items.push({
                                name: i,
                                label: capitalize(str),
                                sort: null,
                            });
                        });
                    this.headers = items;
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (this.list?.length > 0) {
            this.origList = [...this.list];
            let items = [];
            Object.keys(this.list[0])
                .filter(
                    (k) =>
                        k !== "id" &&
                        k !== "opportunityId" &&
                        k !== "ventureId" &&
                        k !== "isArchived"
                )
                .map((i) => {
                    let str = i.split(/(?=[A-Z])/);
                    str = str.join(" ");
                    items.push({
                        name: i,
                        label: capitalize(str),
                        sort: null,
                    });
                });
            this.headers = items;
        }
    },
};
</script>

<style>
.progress-bar {
    width: 100px;
    height: 20px;
    display: inline-flex;
}
</style>
